import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
  ) { }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiURL}${path}`, { params });
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiURL}${path}`,
      body
    );
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiURL}${path}`,
      body
    );
  }daysChoosed
  
  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.apiURL}${path}`
    );
  }

  pdfDownloadFile(path: string, formData: any): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiURL}${path}`,
      formData,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ));
  }
  memberActiveDownloadFile(path: string, formData: any): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiURL}${path}`,
      formData,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ));
  }

  expenseDownloadFile(path: string, params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {


    return this.http.request(new HttpRequest(
      'GET',
      `${environment.apiURL}${path}`,
      {
        params: params,
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  pdfTrainerDownloadFile(path: string, formData: any): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiURL}${path}`,
      formData,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ));
  }

  downloadFile(path: string, paramsConfig = { fileName: '' }): Observable<HttpEvent<Blob>> {

    if (!paramsConfig.fileName) {
      console.error('mention fileName with your params');
      return;
    }

    let params = new HttpParams();
    for (let key in paramsConfig) {
      params = params.set(key, paramsConfig[key]);
    }

    return this.http.request(new HttpRequest(
      'GET',
      `${environment.apiURL}${path}`,
      // Object.keys(paramsConfig).length > 0 ?  params : null,
      params,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  downloadFileForFees(path: string, paramsConfig = { fileName: '' }): Observable<HttpEvent<Blob>> {

    if (!paramsConfig.fileName) {
      console.error('mention fileName with your params');
      return;
    }

    let params = new HttpParams();
    for (let key in paramsConfig) {
      params = params.set(key, paramsConfig[key]);
    }

    return this.http.request(new HttpRequest(
      'GET',
      `${environment.invoiceURL}${path}`,
      // Object.keys(paramsConfig).length > 0 ?  params : null,
      params,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  

  FilterDownloadFile(path: string, params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {


    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiURL}${path}`,
      {
        params: params,
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  // allEmployeeDownloadFile(params: any): Observable<Blob> {
  //   let httpParams = new HttpParams();
  //   for(let key in params) {
  //     httpParams = httpParams.set(key, params[key]);
  //   }
  //   return this.http.get(`http://192.168.1.75:8000/api/V1/GP/export_employee`, {
  //     params: httpParams,
  //     reportProgress: true,
  //     responseType: 'blob'
  //   });
  // }

  // weightEmployeeDownloadFile(params: HttpParams = new HttpParams()): Observable<Blob> {
  //   return this.http.get(`http://192.168.1.75:8000/api/V1/GP/weightmeasure/download`, {
  //     params: params,
  //     reportProgress: true,
  //     responseType: 'blob'
  //   })
  // }

  weightEmployeeDownloadFile(path: string, formData: any): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiURL}${path}`,
      formData,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    ));
  }
  // FilterDownloadFile1(path: string, params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {


  //   return this.http.request(new HttpRequest(
  //     'GET',
  //     `${environment.apiURL}${path}`,
  //     params,
  //     {
  //       reportProgress: true,
  //       responseType: 'blob'
  //     }));
  // }
  individualDownloadFile(path: string, formData: any): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest('POST', `${environment.apiURL}${path}`, formData, {
      reportProgress: true,
      responseType: "blob"
    }));
  }

  weekDownloadFile(path: string, formData: any): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest('POST', `${environment.apiURL}${path}`, formData, {
      reportProgress: true,
      responseType: "blob"
    }));
  }
  // pdfDownloadFile(path: string, formData: any): Observable<HttpEvent<Blob>> {
  //   return this.http.request(new HttpRequest(
  //     'POST',
  //     `${environment.apiURL}${path}`,
  //     formData,
  //     {
  //       reportProgress: true,
  //       responseType: 'blob'
  //     }
  //   ));
  // }

  uploadFile(file: Blob, path = "upload"): Observable<HttpEvent<void>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiURL}${path}`,
      formData,
      {
        reportProgress: true
      }));
  }
  //   getTrackingData(path: string, params: HttpParams = new HttpParams()): Observable<any> {
  //     return this.http.get(`${environment.trackingURL}${path}`, { params });
  //   }


  //   ///// device api call /////
  //   deviceget(path: string, params: HttpParams = new HttpParams()): Observable<any> {
  //     return this.http.get(`${environment.deviceApiURl}${path}`, { params });
  //   }

  //   deviceput(path: string, body: Object = {}): Observable<any> {
  //     return this.http.put(
  //       `${environment.deviceApiURl}${path}`,
  //       body
  //     );
  //   }

  //   devicepost(path: string, body: Object = {}): Observable<any> {
  //     return this.http.post(
  //       `${environment.deviceApiURl}${path}`,
  //       body
  //     );
  //   }

  //   devicedelete(path: string, body: Object = {}): Observable<any> {
  //     return this.http.delete(
  //       `${environment.deviceApiURl}${path}`, body
  //     );
  //   }
  // DownloadFileDevice(path: string,  params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {


  //     return this.http.request(new HttpRequest(
  //       'GET',
  //       `${environment.deviceApiURl}${path}`,
  //       {
  //         params:params,
  //         reportProgress: true,
  //         responseType: 'blob'
  //       }));
  //   }
  //   // no movement device url 
  //   noMovepost(body: Object = {}): Observable<any> {
  //     return this.http.post(
  //       `${environment.noMovementUrl}`,
  //       body
  //     );
  //   }
  //   // delete dp 
  //   deleteDP(body: Object = {}): Observable<any> {
  //     return this.http.post(
  //       `http://petal-school-development.us-east-1.elasticbeanstalk.com/track/delete/restricted/nomovement`,
  //       body
  //     );
  //   }

  //   getTodayTimeline(params: HttpParams = new HttpParams()): Observable<any> {
  //     return this.http.get(`${environment.todayTimeline}`, { params });
  //   }

  //   subscribeHub(body: Object = {}): Observable<any> {
  //     return this.http.post(`${environment.subscribeHub}`, body );
  //   }

  //   unsubscribeHub(body: Object = {}): Observable<any> {
  //     return this.http.post(`${environment.unsubscribeHub}`,  body );
  //   }
}
